import {Base_Props} from '../App/App'
import {useForm} from 'react-hook-form'
import './Host_Game_Setup.css'
import {
	Client_Event_Name,
	Host_Event_Name,
} from '../../../common/src/state/Event_And_State_Names'
import 'react-toggle/style.css'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import toast from 'react-hot-toast'
import Toggle from 'react-toggle'
import { useEffect, useState } from 'react'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'

type Add_Team_Form_Values = {
	team_name: string
}

export function Host_Game_Setup(props: Base_Props) {
	const {
		game_code,
		teams,
		players,
		teams_enabled,
		is_trial,
	} = props.context

	useEffect(() => {
		if (is_trial) {
			show_help_tip('teams_setup')
		}
	}, []) 

	const {send} = props
	const {
		register,
		resetField,
		handleSubmit,
		formState: {errors},
	} = useForm<Add_Team_Form_Values>()
	const onSubmit = handleSubmit(data => {
		send({type: Client_Event_Name.add_team, detail: data})
		resetField('team_name')
	})

	const [selected_tab, set_selected_tab] = useState('teams')

	const [player_help_tip_seen, set_player_help_tip_seen] = useState(false)

	const switch_to_teams = () => set_selected_tab('teams')
	const switch_to_players = () => {
		set_selected_tab('players')
		if (is_trial && !player_help_tip_seen) {
			set_player_help_tip_seen(true)
			show_help_tip('players_setup')
		}
		
	}

	const toggle_teams = () => {
		send({type: Host_Event_Name.set_teams_enabled, detail: { teams_enabled: !teams_enabled}})
	}

	const remove_team = (team_name: string) =>
		send({type: Host_Event_Name.remove_team, detail: {team_name}})

	const remove_player = (player_name: string) =>
		send({type: Host_Event_Name.remove_player, detail: {player_name}})

	const start_game = () => send({type: Host_Event_Name.start_game})

	const save_and_exit = () => send({type: Host_Event_Name.save_and_exit})
	const link =
		window.location.protocol +
		'//' +
		window.location.host +
		`#${game_code.toUpperCase()}`

	const copy_link_to_clipboard = () => {
		navigator.clipboard.writeText(link).then(() => {
			toast.success(`link copied to clipboard, send it to your players to join`, {
				position: 'top-center',
			})
		}).catch(e => {
			toast.error(`copy to clipboard failed, ${e}`)
		})
		
	}

	const send_cancel_game = () => send({type: Client_Event_Name.cancel_game})

	// generate a map of which teams players are in:
	// TODO: move this to state machine and use it for this and the all_players_are_in_teams guard
	const player_teams: {[index: string]: string} = {}
	teams.list.map(team =>
		team.members.list.map(player => (player_teams[player.name] = team.name)),
	)

	const generate_teams_list = () => {
		if (!teams_enabled) return <></>

		if (teams.list.length === 0) {
			return <></>
		}
		return teams.list.map(team => (
			<div className="team_container">
				<div className="team_left_side">
					<div className="team_name">{team.name}</div>
					<button
						className="negative mini_text"
						data-testid={`remove team ${team.name}`}
						title={`remove team ${team.name}`}
						onClick={() => remove_team(team.name)}>
						<span>
							remove
							<br />
							team
						</span>
					</button>
				</div>
				<div className="team_right_side">
					<div>Team members:</div>
					<div className="team_players_box">
						<span className="small-text">{team.members.list.length === 0 && 'waiting for players to join team..'}</span>
						{team.members.list.map(member => (
							<div className="player_item" data-player-item={member.name}>
								{member.name}
							</div>
						))}
					</div>
				</div>
			</div>
		))
	}

	const header = (
		<>
			<header className="page_header">
				Game setup
			</header>
			<div className="hidden">
				<input data-testid="game_code" value={game_code} />
			</div>
		</>
	)

	const content = (
		<>
			<div className="host_game_setup">
				<div className='tab_button_row'>
					<button onClick={switch_to_teams} className={`tab_button ${selected_tab === 'teams' ? 'selected' : ''}`}>Teams</button>
					<button onClick={switch_to_players} className={`tab_button ${selected_tab === 'players' ? 'selected' : ''}`}>Players</button>
				</div>
				<div className="tab_set">
					<div className={`tab_content teams ${selected_tab === 'teams' ? 'selected' : ''}`}>
						<div className="tab_under left"><div className="tab_under_inner"></div></div>
						<div className="header_under_tabs" data-help-tip="teams_setup">
							<div>
								<form onSubmit={onSubmit}>
									<div className="add_team_section">
										<div className="teams_toggle">Teams <Toggle className='pink-toggle' onChange={toggle_teams} defaultChecked={teams_enabled} /></div>
										{ teams_enabled ? <>
										<div className="add_team_input_section">
										<div
											className={
												errors?.team_name ? 'validation_warn_attach' : 'hidden'
											}>
											limit: 20 characters
										</div>
										
										<input
											className={'team_name_input ' + (errors?.team_name ? 'validation_warn' : '')}
											{...register('team_name', {required: true, maxLength: 20})}
											placeholder="new team name"
										/>
										<button className="input_attach main_action" type="submit">+</button>
										</div>
										</> : <span className="teams_disabled_message">Players play individually</span>}
									</div>
								</form>
							</div>
						</div>
						{generate_teams_list()}
					</div>
					<div className={`tab_content players ${selected_tab === 'players' ? 'selected' : ''}`}>
					<div className="tab_under right"><div className="tab_under_inner"></div></div>
						<div className="header_under_tabs" data-help-tip="players_setup">
							<button
								className="main_action copy_join_link icon_button"
								onClick={copy_link_to_clipboard}>
								<svg
									fill="#000000"
									width="1.5em"
									height="1.5em"
									viewBox="0 0 36 36"
									version="1.1"
									preserveAspectRatio="xMidYMid meet"
									xmlns="http://www.w3.org/2000/svg">
									<title>copy-to-clipboard-line</title>
									<path
										fill="currentColor"
										stroke="currentColor"
										d="M22.6,4H21.55a3.89,3.89,0,0,0-7.31,0H13.4A2.41,2.41,0,0,0,11,6.4V10H25V6.4A2.41,2.41,0,0,0,22.6,4ZM23,8H13V6.25A.25.25,0,0,1,13.25,6h2.69l.12-1.11A1.24,1.24,0,0,1,16.61,4a2,2,0,0,1,3.15,1.18l.09.84h2.9a.25.25,0,0,1,.25.25Z"></path>
									<path
										fill="currentColor"
										stroke="currentColor"
										d="M33.25,18.06H21.33l2.84-2.83a1,1,0,1,0-1.42-1.42L17.5,19.06l5.25,5.25a1,1,0,0,0,.71.29,1,1,0,0,0,.71-1.7l-2.84-2.84H33.25a1,1,0,0,0,0-2Z"></path>
									<path
										fill="currentColor"
										stroke="currentColor"
										d="M29,16h2V6.68A1.66,1.66,0,0,0,29.35,5H27.08V7H29Z"></path>
									<path
										fill="currentColor"
										stroke="currentColor"
										d="M29,31H7V7H9V5H6.64A1.66,1.66,0,0,0,5,6.67V31.32A1.66,1.66,0,0,0,6.65,33H29.36A1.66,1.66,0,0,0,31,31.33V22.06H29Z"></path>
									<rect x="0" y="0" width="36" height="36" fill-opacity="0" />
								</svg>
								<div>Invite players</div>
							</button>
						</div>
						<div>
							<table className={`host_player_list ${teams_enabled ? 'teams_enabled' : ''}`}>
								<thead>
									<tr>
										<th>Player</th>
										{teams_enabled ? <th>Team</th> : undefined}
										<th></th>
										<th></th>
									</tr>
								</thead>
								{players.list.length === 0 && (
									<tr>
										<td colSpan={3}>Players will appear here when they join..</td>
									</tr>
								)}
								<tbody>
									{players.list.map(player => (
										<tr>
											<td>
												<div
													className="player_item"
													data-player-item={player.name}>
													{player.name}
												</div>
											</td>
											{teams_enabled ? <td>{player_teams[player.name] || '-'}</td> : undefined}
											<td>
												<div
													className={
														player.connected
															? 'socket_connected'
															: 'socket_disconnected'
													}></div>
											</td>
											<td>
												<button onClick={() => remove_player(player.name)}>
													X
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Help_Tips id="teams_setup">
				<div>
					<h2>Setting up your game</h2>
					<p>
						Add and remove teams here or turn teams off altogether if you want your players to play individually. 
					</p>
					<p>
						At any time during a game you can pause and change these settings so feel free
						to mess around with these settings now.
					</p>
					<p>
						When you're happy click on the players tab to invite people.
					</p>
				</div>
			</Help_Tips>
			<Help_Tips id="players_setup">
				<div>
					<h2>Inviting players</h2>
					<p>
						This button copy/pastes a link into your clipboard, you need to send it to your players
						and when they open it they will just need to enter their player name and click join.
					</p>
					<p>
						Your players will appear in the list when they join, the green dot means they're connected,
						or red if they disconnect. You can remove players with the X.
					</p>
					<p>
						Once you're in the game you can pause it at any time to add or remove players.
					</p>
				</div>
			</Help_Tips>
		</>
	)

	const controls = (
		<>
			<div className="two_buttons">
				<button className="main_action" onClick={start_game}>
					Start game
				</button>
			</div>
			<div className='two_buttons'>
				<button className="negative" onClick={send_cancel_game}>
					Cancel game
				</button>
				<button className="side_action" onClick={save_and_exit}>
					Save and exit
				</button>
			</div>
			
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
