import './Host_Unverified.css'
import {Client_Event_Name, Host_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import { Client_State_Name } from '../State/player_machine'

export function Host_Unverified(props: Base_Props) {
	const {send} = props

	const send_cancel_game = () => send({type: Client_Event_Name.cancel_game})

	const move_next = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.target) {
			;(
				(event.target as HTMLInputElement).nextElementSibling as HTMLElement
			)?.focus()
		}
		send_verification()
	}

	const send_verification = () => {

		const type = props.state_value === Client_State_Name.host_game_unverified ? Client_Event_Name.verify : Host_Event_Name.resume_game;

		const d1 =
			document.querySelector<HTMLInputElement>('#verification_digit_1')
				?.value || ''
		const d2 =
			document.querySelector<HTMLInputElement>('#verification_digit_2')
				?.value || ''
		const d3 =
			document.querySelector<HTMLInputElement>('#verification_digit_3')
				?.value || ''
		const d4 =
			document.querySelector<HTMLInputElement>('#verification_digit_4')
				?.value || ''
		if (d1 !== '' && d2 !== '' && d3 !== '' && d4 !== '') {
			send({
				type,
				detail: {
					one_time_verification_code: d1 + d2 + d3 + d4,
					host_email: props.context.host_email
				},
			})
		}
	}

	const header = (
		<>
			<div className="page_header">Verify game</div>
			<div className="sub_header">check your email to verify the game</div>
		</>
	)

	const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => event.target.select();

	const content = (
		<>
			<div className="header_over_form">
				<div className="h2">verification email sent to</div>
				<div className="email_pod">{props.context.host_email}</div>
				<div className="h2">enter the 4 digit code here:</div>
			</div>
			
			<div className="verification_inputs">
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_1"
					data-testId="verification_digit_1"
					className="single_digit_input"
					onKeyUp={move_next}></input>
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_2"
					data-testId="verification_digit_2"
					className="single_digit_input"
					onKeyUp={move_next}></input>
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_3"
					data-testId="verification_digit_3"
					className="single_digit_input"
					onKeyUp={move_next}></input>
				<input
					onFocus={handleFocus}
					type="number"
					id="verification_digit_4"
					data-testId="verification_digit_4"
					className="single_digit_input"
					onKeyUp={move_next}></input>
			</div>
			<div className="hidden">
				<input data-testid="game_code" value={props.context.game_code} />
			</div>
		</>
	)

	const controls = (
		<div className='two_buttons'>
			<button className="negative" onClick={send_cancel_game}>
				Cancel game
			</button>
		</div>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
