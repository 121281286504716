import { CUSTOM_QUESTIONS, Subject_Listing } from "../../../common/src/models/Trivia"
import { Host_Event_Name } from "../../../common/src/state/Event_And_State_Names"
import { Base_Props } from "../App/App"


export function Host_In_Game_Questions_Content(props: Base_Props) {
    const { send, context } = props

    const { 
        chosen_trivia_topic, 
        whos_buzzer_is_active, 
        trivia_topics, 
        round_num,
        question_num,
        question_text,
        answer_text, 
    } = context

	const get_trivia_topics = () => send({ type: Host_Event_Name.get_trivia_topics})
	const use_my_own_questions = () => send({ type: Host_Event_Name.choose_trivia_topic, detail: { subject: {field: CUSTOM_QUESTIONS, name: CUSTOM_QUESTIONS} }})
	const choose_topic = (subj: Subject_Listing) => send({ type: Host_Event_Name.choose_trivia_topic, detail: { subject: subj }})
	const skip_question = () => send({type: Host_Event_Name.skip_question})
	const send_correct = () => send({type: Host_Event_Name.answer_is_correct, detail: { score_diff: 10 }})
	const send_incorrect = () => send({type: Host_Event_Name.answer_is_incorrect, detail: { score_diff: -5 }})

	const generate_trivia_topic_list = () => {
		if (trivia_topics?.fields.length) {
			return <>
				{trivia_topics.fields.map(field => (
				<div className="trivia_field_listing">
					<div className="trivia_field_name">{field.name}</div>
					<ul>
						{field.subjects.map(subj => <li onClick={() => choose_topic(subj)}>{subj.name}</li>)}
					</ul>
				</div>
			))}
			</>
		}
		else {
			return <div className="round_number_banner">
				<div className="round_number_header">Round {round_num}!</div>
				<div className="round_number_subheader">setup</div>
			</div>
		}
	}

    const get_topic_name_display = () => {
		if (!chosen_trivia_topic) {
			return ''
		}
		else if (chosen_trivia_topic.field === CUSTOM_QUESTIONS) {
			return 'Custom round'
		}
		return `${chosen_trivia_topic.field} - ${chosen_trivia_topic.name}`
	}

	const buzzer_active = !!whos_buzzer_is_active


    return !chosen_trivia_topic ?
        <>
            <div className="header_under_tabs">
                <div className='two_buttons' data-help-tip='game_start'>
                    <button className="main_action choose_topic" onClick={get_trivia_topics}>Choose topic</button>
                    <button className="side_action choose_topic" onClick={use_my_own_questions}>Use my own</button>
                </div>
            </div>
            <div>
                {generate_trivia_topic_list()}
            </div>
        </> :
        <>
            <div className="header_under_tabs">
                <div className="buzzed_section">
                    <button
                        disabled={!buzzer_active}
                        className="negative"
                        onClick={send_incorrect}>
                        <span className="smaller">Incorrect</span>
                    </button>

                    <div className="who_buzzed_frame">
                        <div className="buzzed_label">
                            <span>Buzzed:</span>
                        </div>
                        <div
                            data-testId="who_buzzed"
                            className={`who_buzzed ${whos_buzzer_is_active ? 'active' : ''}`}>
                            <span className="smaller">{whos_buzzer_is_active ? whos_buzzer_is_active : 'waiting for buzz'}</span>
                        </div>
                    </div>
                    <button className="side_action" disabled={!buzzer_active} onClick={send_correct}>
                        <span className="smaller">Correct</span>
                    </button>
                </div>
            </div>
            <div>
                <div className='question_display'>
                    <div className="chosen_trivia_topic">Topic: {get_topic_name_display()}</div>
                    <div className="question_header"><span className="QA_Icon">Q{question_num}</span>{question_text}</div>
                    <div className="answer_header"><span className="QA_Icon">A{question_num}</span>{answer_text} <button className="inline tiny" onClick={skip_question}>Skip it</button></div>
                </div>
            </div>
        </>
}