import './style/normalize.css'
import './style/Colours.css'
import './style/Page_Layout.css'
import './style/Text.css'
import './style/App.css'
import './style/User_Advice.css'
import './style/Checkbox.css'
import '../Components/Button_Container_Area.css'
import '../Components/Buttons.css'
import '../Components/Toggle.css'
import qb_icon from '../../assets/qb-icon.svg'
import {Splash_Page} from '../Splash_Page/Splash_Page'
import {
	PlayerContext,
	Client_State_Name,
	playerMachine,
} from '../State/player_machine'
import {useActor} from '@xstate/react'
import {AnyEventObject} from 'xstate'
import {Player_Game_Setup} from '../Player_Game_Setup/Player_Game_Setup'
import {Player_Registration} from '../Player_Registration/Player_Registration'
import {Host_Registration} from '../Host_Registration/Host_Registration'
import {Host_Game_Setup} from '../Host_Game_Setup/Host_Game_Setup'
import {Host_In_Game} from '../Host_In_Game/Host_In_Game'
import {Player_In_Game} from '../Player_In_Game/Player_In_Game'
import {useEffect} from 'react'
import {Key_For_XState_Persistence} from './main'
import {Player_Machine_Snapshot} from '../State/types'
import {Connection_Closed} from '../Connection_Closed/Connection_Closed'
import {Websocket_Event_Names} from '../State/websockets_machine'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Host_Game_Finished} from '../Host_Finished/Host_Finished'
import {Error_Boundary} from './Error_Boundary'
import {Host_Unverified} from '../Host_Unverified/Host_Unverified'
import {Modal_Popup} from '../Modal_Popup/Modal_Popup'
import {Toaster} from 'react-hot-toast'
import {Player_Finished} from '../Player_Finished/Player_Finished'
import {Host_Ran_Out_Of_Games} from '../Host_Ran_Out_Of_Games/Host_Ran_Out_Of_Games'
import '../Help_Tips/Help_Tips.css'
import { app_version, app_version_hash } from '../../../common/version'
import Hammer from 'hammerjs'
import { Hamburger_Menu } from '../Hamburger_Menu/Hamburger_Menu'
import { Help_Tips } from '../Help_Tips/Help_tips'
export interface Base_Props {
	send: (event: AnyEventObject) => void
	context: PlayerContext,
	state_value: string
}

// This snapshot type was just copied from XState to satisfy the typescript checker.
interface App_Props {
	snapshot?: Player_Machine_Snapshot
}

let document_font_size = document.querySelector('#m-space-measure')?.clientWidth || 16;
let new_font_size = 16

export function App(props: App_Props) {
	// Initiate the player machine, pass in a snapshot if it
	// was persisted to storage.
	const [state, send, service] = useActor(playerMachine, {
		snapshot: props.snapshot,
	})

	if (state.value === 'splash' && window.location.hash.length === 5) {
		send({type: 'start_player_registration', detail: {source: 'app-load'}})
	}

	window.addEventListener('hashchange', () => {
		if (state.value === 'splash' && window.location.hash.length === 5) {
			send({
				type: 'start_player_registration',
				detail: {source: 'url-hash-change-handler'},
			})
		}
	})

	const show_version = () => {
		(document.querySelector('#app-version') as HTMLDivElement).style.color = "white"
		window.setTimeout(() => {
			(document.querySelector('#app-version') as HTMLDivElement).style.color = "#00000000";
		}, 800)
	}

	const context: PlayerContext = state.context

	// These effects are for the first time loading the app only:
	useEffect(() => {
		send({type: Websocket_Event_Names.websocket_closed})

		// Prevent back button and send an event instead,
		// TODO: in future be selective about this by adding the pushState call in to the state machine
		// so that you can back through states and then out of the site completely
		history.pushState({state: 'application started'}, 'Quiz Buzz', '')
		addEventListener('popstate', () => {
			send({type: Client_Event_Name.navigate_back})
		})

		service.subscribe(snapshot => {
			if (snapshot.value === Client_State_Name.host_finished) {
				sessionStorage.removeItem(
					Key_For_XState_Persistence
				)
			}
			else {
				sessionStorage.setItem(
					Key_For_XState_Persistence,
					JSON.stringify(service.getPersistedSnapshot()),
				)
			}
			
			console.log('new state: ', snapshot.value, snapshot)
		})

		const zoomer = new Hammer(document.body)
		zoomer.get('pinch').set({ enable: true });

		zoomer.on('pinch', (event) => {
			console.log(event.scale)
			const scaling_factor = ((event.scale - 1) * 0.3) + 1
			new_font_size = document_font_size * scaling_factor
			// new_font_size = Math.min(new_font_size, MAX_SCALING_FACTOR)
			// new_font_size = Math.max(new_font_size, MIN_SCALING_FACTOR)
			document.body.style.fontSize = new_font_size + 'px'
		})

		zoomer.on('pinchend', () => {
			document_font_size = new_font_size
		})

		// I dont want this effect running on every state change, only on init. So I need to read
		// more about react-hooks/exhaustive-deps and why running a subscription from an effect is bad.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [service])

	return (
		<div className="app" data-help-tips-base="base">
			<Error_Boundary>
				<img className="logo-home" src={qb_icon}></img>
				<Hamburger_Menu send={send} context={context}/>
				{state.value === Client_State_Name.splash && (
					<Splash_Page send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.registering_player && (
					<Player_Registration send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.player_game_setup && (
					<Player_Game_Setup send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.player_in_game && (
					<Player_In_Game send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.player_finished && (
					<Player_Finished send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.registering_host && (
					<Host_Registration send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.host_game_unverified && (
					<Host_Unverified send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.host_resume_unverified && (
					<Host_Unverified send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.ran_out_of_games && (
					<Host_Ran_Out_Of_Games send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.host_game_setup && (
					<Host_Game_Setup send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.host_in_game && (
					<Host_In_Game send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.host_finished && (
					<Host_Game_Finished send={send} context={context} state_value={state.value}/>
				)}
				{state.value === Client_State_Name.connection_closed && (
					<Connection_Closed send={send} context={context} state_value={state.value}/>
				)}
				{typeof(state.value) === "string" && (
					<Modal_Popup send={send} context={context} state_value={state.value}/>
				)}
				<Help_Tips id="help_its_broken">
				<div>
					<h2>Something has gone wrong?</h2>
					<p>
						We're really sorry to hear it, if you're hosting and the app wont recover or reconnect
						you can reload the page, or open <a href="https://www.quiz-buzz.com">Quiz Buzz</a> in a fresh
						browser page and resume hosting your game from there.
					</p>
				</div>
				</Help_Tips>
				
				<Toaster position="bottom-center" />
				<div id="app-version" className="app-version" onClick={show_version}>
					<div>App Version: {app_version}</div>
					<div>App Hash: {app_version_hash}</div>
				</div>
			</Error_Boundary>
		</div>
	)
}

export default App
