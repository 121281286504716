import { Player, Team } from "../../../common/src/models/Base_Concepts";
import { Host_Event_Name } from "../../../common/src/state/Event_And_State_Names";
import { Base_Props } from "../App/App";

export function Host_In_Game_Players_And_Teams_Content(props: Base_Props) {
    const { send, context } = props

    const { teams_enabled, teams, whos_buzzer_is_active, players } = context

	const modify_team_score = (team: Team, diff: number) =>
		send({
			type: Host_Event_Name.change_team_score,
			detail: {
				team_name: team.name,
				score_diff: team.score + diff,
			},
		})
	const modify_player_score = (player: Player, diff: number) =>
		send({
			type: Host_Event_Name.change_player_score,
			detail: {
				player_name: player.name,
				score_diff: player.score + diff,
			},
		})

    return <>
        {teams_enabled && teams.list.map((team, i) => (
            <div data-testId={`team row ${team.name}`} className="team_container">
                <div className="team_left_side">
                    <div className="host_team_name">{team.name}</div>
                    <div className="scores_box">
                        <div className="score_buttons">
                            <div>
                                <button
                                    data-testId={`team_score_plus_1_${i}`}
                                    onClick={() => modify_team_score(team, 1)}>
                                    +1
                                </button>
                                <button
                                    data-testId={`team_score_minus_1_${i}`}
                                    onClick={() => modify_team_score(team, 5)}>
                                    +5
                                </button>
                            </div>
                            <div>
                                <button
                                    data-testId={`team_score_plus_5_${i}`}
                                    className="negative"
                                    onClick={() => modify_team_score(team, -1)}>
                                    -1
                                </button>
                                <button
                                    data-testId={`team_score_minus_5_${i}`}
                                    className="negative"
                                    onClick={() => modify_team_score(team, -5)}>
                                    -5
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team_center">
                    <div>Score:</div>
                    <div data-testId={`team_score_${i}`} className="score_box">
                        {team.score}
                    </div>
                </div>
                <div className="team_right_side">
                    <div>Team members:</div>
                    <div className="team_players_box">
                        {team.members.list.map(member => (
                            <div
                                className={`player_item ${
                                    whos_buzzer_is_active === member.name ? 'buzzed' : ''
                                }`}>
                                {member.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ))}

        {!teams_enabled && (
            <>
                <table className="host_in_game_player_list">
                    <thead>
                        <tr>
                            <th>Player</th>
                            <th>Score</th>
                            <th></th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    {players.list.length === 0 && (
                        <tr>
                            <td colSpan={3}></td>
                        </tr>
                    )}
                    <tbody>
                        {players.list.map((player, i) => (
                            <tr>
                                <td>
                                    <div className="player_item" data-player-item={player.name}>
                                        {player.name}
                                    </div>
                                </td>
                                <td
                                    data-testId={`player_score_${i}`}
                                    className="player_score">
                                    {player.score}
                                </td>
                                <td>
                                    <div
                                        data-testId={`player_connected_${i}`}
                                        className={
                                            player.connected
                                                ? 'socket_connected'
                                                : 'socket_disconnected'
                                        }></div>
                                </td>
                                <td>
                                    <button
                                        data-testId={`player_neg_button_${i}`}
                                        className="negative"
                                        onClick={() => modify_player_score(player, -5)}>
                                        -5
                                    </button>
                                    <button
                                        data-testId={`player_pos_button_${i}`}
                                        onClick={() => modify_player_score(player, +5)}>
                                        +5
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )}
    </>

}