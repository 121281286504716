import './Host_In_Game.css'
import {
	Client_Event_Name,
	Host_Event_Name,
} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'
import { useEffect, useState } from 'react'
import { Host_In_Game_Players_And_Teams_Content } from './Host_In_Game_Players_And_Teams_Content'
import { Host_In_Game_Questions_Content } from './Host_In_Game_Questions_Content'

export function Host_In_Game(props: Base_Props) {
	const {
		send,
		context: {
			chosen_trivia_topic,
			round_num,
			is_trial,
			teams_enabled,
		},
	} = props

	useEffect(() => {
		if (is_trial) {
			show_help_tip('game_start')
		}
	}, [])

	const clear_buzz = () => send({type: Client_Event_Name.clear_buzz})
	const finish_round = () => send({type: Host_Event_Name.finish_round})
	const finish_game = () => send({type: Host_Event_Name.finish_game})
	const return_to_setup = () => send({type: Host_Event_Name.return_to_setup})

	const header = (
		<>
			<div className="page_header">{!chosen_trivia_topic ? 'Choose a topic' : 'Game on!'}</div>
			<div className="sub_header">Round: {round_num}</div>
		</>
	)

	const [selected_tab, set_selected_tab] = useState('questions')

	const switch_to_players_and_teams = () => set_selected_tab('players_and_teams')
	const switch_to_questions = () => set_selected_tab('questions')

	const content = (
		<>
			<div className='tab_button_row'>
				<button onClick={switch_to_questions} className={`tab_button ${selected_tab === 'questions' ? 'selected' : ''}`}>Questions</button>
				<button onClick={switch_to_players_and_teams} className={`tab_button ${selected_tab === 'players_and_teams' ? 'selected' : ''}`}>{ teams_enabled ? 'Teams' : 'Players'}</button>
			</div>
			<div className="tab_set">
				<div className={`tab_content questions ${selected_tab === 'questions' ? 'selected' : ''}`}>
					<div className="tab_under left"><div className="tab_under_inner"></div></div>
					<Host_In_Game_Questions_Content context={props.context} send={send} state_value={props.state_value} />
				</div>
				<div className={`tab_content players ${selected_tab === 'players_and_teams' ? 'selected' : ''}`}>
					<div className="tab_under right"><div className="tab_under_inner"></div></div>
					<Host_In_Game_Players_And_Teams_Content context={props.context} send={send} state_value={props.state_value} />
				</div>
			</div>
		</>
	)

	const controls = (
		<>
			<div className="two_buttons">
				<button className="side_action" onClick={clear_buzz}>Clear buzzers</button>
				<button onClick={finish_round}>Finish Round</button>
			</div>
			<div className='two_buttons'>
				<button className="side_action" onClick={return_to_setup}>
					Pause Game
				</button>
				<button className="negative" onClick={finish_game}>
					Finish game
				</button>	
			</div>
			<Help_Tips id="game_start">
				<div>
					<h2>Before each round: pick a topic</h2>
					<p>
						At the start of each round you can choose a trivia topic from our list or
						you can tap 'Use my own' and the game will just keep track of the questions
						and the scores.
					</p>
					<p>
						Once you choose, you'll see the questions, players and teams.
					</p>
					
				</div>
			</Help_Tips>
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
