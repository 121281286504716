import {Base_Props} from '../App/App'
import './Connection_Closed.css'
import {Websocket_Event_Names} from '../State/websockets_machine'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import { Standard_Page_Layout } from '../Components/Standard_Page_Layout'

export function Connection_Closed(props: Base_Props) {
	const {game_code} = props.context

	const {send, context} = props

	const retry_connection = () =>
		send({type: Websocket_Event_Names.connect_websocket})
	const reset_game = () => send({type: Client_Event_Name.reset_game})

	const header_section = <>
		<header className="page_header">Problem!</header>
		{context.game_code ? <header className="sub_header">game: {game_code}</header> : <></>}
	</>

	const content_section = <>
		<div className="two_buttons">
		{context.game_code ? (
			<button onClick={retry_connection}>Reconnect</button>
		) : (
			<button onClick={reset_game}>Back to home</button>
		)}
		</div>
	</>

	return (
		<Standard_Page_Layout
			header={header_section}
			content={content_section}
			controls={<></>} />
	)
}
