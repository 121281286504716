import { useState } from "react"
import "./Hamburger_Menu.css"
import { Client_Event_Name } from "../../../common/src/state/Event_And_State_Names"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { show_help_tip } from "../Help_Tips/Help_tips"
import { PlayerContext } from "../State/player_machine"
import { AnyEventObject } from "xstate"

type FormValues = {
feedback_text: string
}

export interface Menu_Props {
	send: (event: AnyEventObject) => void
	context: PlayerContext,
}


export function Hamburger_Menu(props: Menu_Props) {

    const [hamburger_menu_open, set_hamburger_menu_open] = useState(false)

    const {
		register,
        resetField,
		handleSubmit,
	} = useForm<FormValues>()

    const help_its_broken = () => {
        show_help_tip('help_its_broken')
    }

    const onSubmit = handleSubmit(data => {
        if (props.context.game_code) {
            props.send({ type: Client_Event_Name.send_feedback, detail: { ...data }})
            resetField('feedback_text')
        }
    })

    const account_info = () => toast.error('not implemented yet sorry')
    const game_stats = () => toast.error('not implemented yet sorry')

    return (
        <>
            <div id="menuToggle" 
                className={hamburger_menu_open ? 'open' : ''}
                onClick={() => set_hamburger_menu_open(!hamburger_menu_open)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul id="menu" className={hamburger_menu_open ? 'open' : ''}>
                <li>
                    <label htmlFor="menuCheckbox" onClick={help_its_broken}>Help! It's broken!</label>
                </li>
                <li><label htmlFor="menuCheckbox" onClick={account_info}>Account Info</label></li>
                <li><label htmlFor="menuCheckbox" onClick={game_stats}>Game Stats</label></li>
                <li>
                    <label>Quick Feedback</label>
                    <form id="feedback_form" onSubmit={onSubmit}>
                        <textarea 
                            disabled={!props.context.game_code}
                            placeholder={!props.context.game_code ? 'host or join to send feedback' : ''}
                            {...register('feedback_text')}
                        />
			            <button type="submit">Send</button>
                    </form>
                    
                </li>
                
                <li>
                    <label htmlFor="menuCheckbox">Or contact us at <br /><a href="mailto:support@quiz-buzz.com">support@quiz-buzz.com</a>, we'd love to hear from you.</label>
                </li>
            </ul>
        </>)
}